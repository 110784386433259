import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { BulkSummaryComponent } from 'src/app/components/bulk-employee-import/bulk-summary/bulk-summary.component';
import { BulkEmployeeImportService } from 'src/app/services/bulkImport/bulk-employee-import.service';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { ToastrService } from 'ngx-toastr';
import { OfficeAttendanceAdminComponent } from '../office-attendance-admin/office-attendance-admin/office-attendance-admin.component';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-office-attendance-import-dialog',
  templateUrl: './office-attendance-import-dialog.component.html',
  styleUrls: ['./office-attendance-import-dialog.component.scss']
})
export class OfficeAttendanceImportDialogComponent implements OnInit {

  public fileName = '';
   public xlFile: File;
   public response: any;
   public allfileContent: any;
   public badFileFormat: boolean;
   public displayStyle = 'none';
   public confirmImportFlag: boolean;
   public uploadBtnLabel = 'Select File';
   public errorMessage = "";
   public reuploadText = "Want to re-upload records?";
   public isReuploading:boolean = false;
 
   constructor(
    public dialogRef: MatDialogRef<OfficeAttendanceAdminComponent>,
     private officeAttendanceService: OfficeAttendanceService,
     public dialog: MatDialog,
     private _location: Location,
     private toasterService: ToastrService,
     private userService: UserService,
     @Inject(MAT_DIALOG_DATA) public data: any
   ) {
   }
 
   ngOnInit(): void {}
 
   //Read selected file's name, and assign xlFile the selected file, and set fileName to file name to display on screen
   readFile(event: Event) {
     this.badFileFormat = false;
     this.xlFile = event[0];
     if (this.xlFile) {
       this.fileName = this.xlFile.name;
     }
   }
 
   //On clicking upload button, create form data with the selected xl file, and call the service to trigger the API
   uploadFile() {
     this.confirmImportFlag = false;
     const formData = new FormData();
     formData.append('file', this.xlFile);
     this.uploadBtnLabel = 'Importing Records...';
     this.fileName = 'Please wait...';
     if(!this.data.hideReupload){
     this.officeAttendanceService.uploadBulkAttendance(formData, this.isReuploading).subscribe(
       (data) => {
         this.toasterService.info("Attendance Records Uploaded")
         this.closeDialog();
       },
       (error) => {
        this.toasterService.error(error.error.title)
         this.badFileFormat = true;
         this.errorMessage = error.error.title;
         this.uploadBtnLabel = 'Select File';
         this.fileName = '';
       }
     );
    }
    else{
      this.userService.uploadSlackIds(formData).subscribe(
        (data) => {
          this.toasterService.info("Slack IDs Uploaded")
          this.closeDialog();
        },
        (error) => {
         this.toasterService.error(error.error)
          this.badFileFormat = true;
          this.errorMessage = error.error;
          this.uploadBtnLabel = 'Select File';
          this.fileName = '';
        }
      );
    }
 
     //Reset variables
     this.allfileContent = '';
     this.response = '';
     this.xlFile = null;
   }

   changeToReupload(fileInput: HTMLInputElement){
    fileInput.click();
    this.reuploadText='';
    this.isReuploading=true;
   }
 
   closeDialog() {
     this.dialogRef.close();
   }
 }