import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficeAttendanceService {

  constructor(private restApiService: RestAPIService) {
  }

  getRecordsByDate(date: string) {
    return this.restApiService.get(`/api/attendance-records/${date}`).pipe(
      map(res => res.body)
    );
  }

  uploadBulkAttendance(formData, isReuploading) {
    if (!isReuploading) {
      return this.restApiService.post('/api/attendance-records', formData);
    } else {
      return this.restApiService.put('/api/attendance-records/re-upload', formData);
    }
  }

  getAllAttendanceRecords(
    date: string, 
    page: number = 0, 
    size: number = 20, 
    q?: string, 
    departments?: string[],
    jobTitles?: string[],
  ): Observable<any> {
    let queryParams = `?date=${date}&page=${page}&size=${size}`;
    
    if (q && q.trim()) {
      queryParams += `&q=${encodeURIComponent(q.trim())}`;
    }
  
    if (departments && departments.length > 0) {
      departments.forEach(department => {
        queryParams += `&departments=${encodeURIComponent(department)}`;
      });
    }
    if (jobTitles && jobTitles.length > 0) {
      jobTitles.forEach(titles => {
        queryParams += `&titles=${encodeURIComponent(titles)}`;
      });
    }
  
    return this.restApiService.get(`/api/attendance-records/admin${queryParams}`);
  }

  getMonthlyAttendanceReport(month: number, year: number): Observable<Blob> {
    return this.restApiService.get(`/api/attendance/admin/monthly-report?month=${month}&year=${year}`, 'blob');
  }
  
}
