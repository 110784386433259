<div>
  <div class="container">
    <div class="row">
      <!-- Search Bar -->
      <div class="col-md-2 search-bar mt-3">
        <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
        <input type="search" class="search-input col-md-10" placeholder="Search"
          (input)="onSearchInput($event.target.value)" />
      </div>

      <!-- Department Filter -->
      <div class="col-md-2 mat-form-field-capabilities mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Capabilities</span>
          </mat-label>
          <mat-select [(ngModel)]="capabilitySearch" (selectionChange)="onFiltreChange()" multiple>
            <mat-option *ngFor="let capability of this.departments" [value]="capability"
              [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              [matTooltipDisabled]="!ifOverflow(cap)" matTooltip="{{ capability }}">
              <div class="options" #cap>{{ capability }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Title filtre -->

      <div class="col-md-2 mat-form-field-title  mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Title</span>
          </mat-label>
          <mat-select [(ngModel)]="jobTitleSearch" (selectionChange)="onFiltreChange()" multiple>
            <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" [matTooltipPosition]="position.value"
              [matTooltipClass]="position.value" [matTooltipDisabled]="!ifOverflow(t)" matTooltip=" {{ jobTitle }}">
              <div class="options" #t>{{ jobTitle }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Bulk Import -->
      <div class="col-md-1 btn mt-3">
          <div class="centered-content" [matTooltipPosition]="position.value"
            (click)="addBulkAttendanceRecords()" [matTooltipClass]="position.value" matTooltip="Bulk Import">
            <img class="img-class-bulk" src="assets/svg/bulk-import.svg" />
          </div>
      </div>

      

      <!-- Date Picker -->
      <div class="col-md-2 from-date mat-form-field-title mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label class="common-heading">Date <span class="contentRed"></span></mat-label>
          <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" autocomplete="off"
            [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Export -->

      <div class="col-md-1 download-employee-bulk  mt-3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="Export Monthly Report" (click)="openExportOptions()">
        <img src="assets/svg/download.svg" />
      </div>

      <pagination-controls class="pagination-controls mt-3" (pageChange)="onPageChange($event)" previousLabel="<"
        nextLabel=">">
      </pagination-controls>


    </div>
  </div>

  <div *ngIf="!isSpinnerActive" class="container">
    <div class="mt-3 row">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">
                <div class="table-header-cell">
                  <span class="name">Name</span>
                </div>
              </th>
              <th colspan="2">
                <div class="table-header-cell">
                  <span class="name">Capability</span>
                </div>
              </th>
              <th colspan="2">
                <div class="table-header-cell">
                  <span class="name">Job Title</span>
                </div>
              </th>
              <th>
                <div class="table-header-cell">
                  <span class="name">Hours</span>
                </div>
              </th>
              <th>
                <div class="table-header-cell">
                  <span class="name">Monthly Hours</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let attendance of filteredAttendances| paginate : { itemsPerPage: this.pageSize, totalItems: this.totalPages * pageSize, currentPage: page }">
              <td colspan="2">
                <div class="row">
                  <label class="data">{{ attendance.firstName }} {{ attendance.lastName }}</label>
                </div>
              </td>
              <td colspan="2">
                <div class="row">
                  <label class="data">{{ attendance.department | underScoreToSpace | titlecase}} </label>
                </div>
              </td>
              <td colspan="2">
                <div class="row">
                  <label class="data">{{ attendance.jobTitle | underScoreToSpace | titlecase }}</label>
                </div>
              </td>
              <td>
                <label class="data">
                  {{ formatMinutes(attendance.totalWorkedMinutes) }}
                </label>
              </td>
              <td>
                <label class="data">
                  {{ formatMinutes(attendance.totalWorkedMinutesForMonth) }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
  <div *ngIf="isSpinnerActive" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>
  <div *ngIf="!isSpinnerActive && attendances.length===0" class="spinner-div">
    <label class="loading_label">No data available for the date: {{selectedDate | date}}</label>
  </div>
  <div *ngIf="!isSpinnerActive && (filteredAttendances.length===0 && !(attendances.length===0))" class="spinner-div">
    <label class="loading_label">No matching records found for given filters, try searching something else...</label>
  </div>

</div>