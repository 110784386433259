import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { saveAs } from 'file-saver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MaterialModule } from './modules/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DndModule } from 'ngx-drag-drop';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgxPaginationModule } from 'ngx-pagination';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { SidenavService } from './services/sidenav.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FgHttpInterceptor } from './services/http-interceptor';
import { PrimengModule } from './modules/primeng.module';
import { MessageService } from 'primeng/api';
import { LoginComponent } from './components/login/login.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EditAppComponent } from './components/edit-app/edit-app.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormViewComponent } from './components/dynamic-form-view/dynamic-form-view.component';
import { TestParentComponent } from './components/OpenModal/test-parent/test-parent.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter, DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { FeedbackFormsComponent } from './components/feedback-forms/feedback-forms.component';
import { OAuthSettings } from 'src/oauth';
import { JwtInterceptor } from './services/jwt-intercepter';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import { FeedbackGenerationComponent } from './components/feedback-generation/feedback-generation.component';
import { ProjectAccordianComponent } from './components/feedback-generation/project-accordian/project-accordian.component';
import { FeedbackGenerationDialogComponent } from './components/feedback-generation/feedback-generation-dialog/feedback-generation-dialog.component';
import { CreateFormsDialogComponent } from './components/feedback-generation/create-forms-dialog/create-forms-dialog.component';
import { FeedbackTabsComponent } from './components/feedback-submission/feedback-tabs/feedback-tabs.component';
import { FeedbackTabContentComponent } from './components/feedback-submission/feedback-tab-content/feedback-tab-content.component';
import { FormTemplateDialogComponent } from './components/feedback-submission/form-template-dialog/form-template-dialog/form-template-dialog.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { MainContentComponent } from './components/employee/main-content/main-content.component';
import { EmployeeCardComponent } from './components/employee/employee-card/employee-card.component';
import { AddEmployeeCardComponent } from './components/employee/add-employee-card/add-employee-card.component';
import { ProjectComponent } from './components/project/project.component';
import { MainContentProjectComponent } from './components/project/main-content-project/main-content-project.component';
import { ProjectCardComponent } from './components/project/project-card/project-card.component';
import { AddProjectCardComponent } from './components/project/add-project-card/add-project-card.component';
import { ProjectDetailViewComponent } from './components/project/project-detail-view/project-detail-view.component';
import { CareerManagerComponent } from './components/career-manager/career-manager.component';
import { FeedbackGenerationCMComponent } from './components/career-manager/feedback-generation-cm/feedback-generation-cm.component';
import { DirectReporteesCMComponent } from './components/career-manager/direct-reportees-cm/direct-reportees-cm.component';
import { FeedbackFormGenerationComponent } from './components/feedback-form-generation/feedback-form-generation.component';
import { MainContentFeedbackComponent } from './components/feedback-form-generation/main-content-feedback/main-content-feedback.component';
import { FeedbackFormCardSharedComponent } from './components/feedback-form-generation/shared/feedback-form-card-shared/feedback-form-card-shared.component';
import { FeedbackFormCardNotificationComponent } from './components/feedback-generation/shared/feedback-form-card-notification/feedback-form-card-notification.component';
import { EvaluationConfigurationComponent } from './components/evaluation-configuration/evaluation-configuration.component';
import { OneOnOneMeetingComponent } from './components/one-on-one-meeting/one-on-one-meeting/one-on-one-meeting.component';
import { ParticipantCardComponent } from './components/one-on-one-meeting/participantCard/participant-card/participant-card.component';
import { GoalSheetComponent } from './components/career-manager/goal-sheet/goal-sheet.component';
import { GoalSheetAccordianComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/goal-sheet-accordian.component';
import { SubGoalTabComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/sub-goal-tab/sub-goal-tab.component';
import { ProgressBarComponent } from './components/career-manager/goal-sheet/progress-bar/progress-bar.component';
import { AddGoalComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/add-goal/add-goal.component';
import { GoalSheetViewEmployeeComponent } from './components/career-manager/goal-sheet/goal-sheet-view-employee/goal-sheet-view-employee.component';
import { AngularSpinnerComponent } from './components/angular-spinner/angular-spinner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AverageScoreComponent } from './components/average-score/average-score.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';

import { ChartsModule } from 'ng2-charts';
import { OrderModule } from 'ngx-order-pipe'
import { HorizontalBarChartComponent } from './components/horizontal-bar-chart/horizontal-bar-chart.component';
import { AllEmployeeReviewComponent } from './components/all-employee-review/all-employee-review.component';
import { PagesComponent } from './components/pages/pages.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { MatTabsModule } from '@angular/material/tabs';
import { HomeComponent } from './components/home/home.component';
import { LeaveSummaryComponent } from './components/home/leave-summary/leave-summary.component';
import { UpcomingHolidaysComponent } from './components/home/upcoming-holidays/upcoming-holidays.component';
import { UpcomingEventsComponent } from './components/home/upcoming-events/upcoming-events.component';
import { MyProjectsComponent } from './components/home/my-projects/my-projects.component';
import { MyTeamComponent } from './components/home/my-team/my-team.component';
import { NotificationsCarouselComponent } from './components/home/notificationsCarousel/notificationsCarousel.component';
import { EmployeeUnmarkedComponent } from './components/employee/employee-unmarked/employee-unmarked.component';
import { DeleteEmployeeComponent } from './components/employee/delete-employee/delete-employee.component';
import { BulkEmployeeComponent } from './components/bulk-employee-import/bulk-employee/bulk-employee.component';
import { EmployeeImportComponent } from './components/home/employee-import/employee-import/employee-import.component';
import { BulkSummaryComponent } from './components/bulk-employee-import/bulk-summary/bulk-summary.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
//import { EmployeeProfileComponent } from './employee-profile/employee-profile.component';
import { TaskComponent } from './components/tasks/task/task.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { ExitChecklistComponent } from './components/exit-checklist/exit-checklist.component';
import { AssetMainComponent } from './components/asset-management/asset-main/asset-main.component';
import { AssetManagementCardComponent } from './components/home/asset-management-card/asset-management-card.component';
import { AddAssetComponent } from './components/asset-management/add-asset/add-asset/add-asset.component';
import { AssetCardComponent } from './components/asset-management/asset-card/asset-card/asset-card.component';
import { ViewAssetComponent } from './components/asset-management/view-asset/view-asset/view-asset.component';
import { RevokeAssetComponent } from './components/asset-management/revoke-asset/revoke-asset/revoke-asset.component';
import { MyTaskComponent } from './components/home/my-task/my-task.component';
import { ResignationemailComponent } from './components/resignationemail/resignationemail.component';

import { ResignationEmailDialogComponent } from './components/resignationemail/resignation-email-dialog/resignation-email-dialog.component';
import { MatTable, MatTableModule } from '@angular/material/table';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { TaskEffects } from './store/effects/task.effect';
import { taskReducer } from './store/reducers/task.reducer';
import { RelivingProcessComponent } from './components/profile-employee/reliving-process/reliving-process.component';
import { ProfileEmployeeComponent } from './components/profile-employee/profile-employee.component';
import { SliderModule } from 'primeng/slider';
import { SpotLightComponent } from './components/profile-employee/spot-light/spot-light.component';
import { CorrespondenceComponent } from './components/profile-employee/correspondence/correspondence.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { AssignAssetComponent } from './components/asset-management/assign-asset/assign-asset/assign-asset.component';
import { AddEditClientComponent } from './components/client-details/add-edit-client/add-edit-client.component';
import { ViewClientComponent } from './components/client-details/view-client/view-client.component';
import { AddTalentComponent } from './components/project/project-detail-view/add-talent/add-talent/add-talent.component';
import { CmGoalSheetComponent } from './components/career-manager/goal-sheet/cm-goal-sheet/cm-goal-sheet.component';
import { AssignGoalComponent } from './components/career-manager/goal-sheet/assign-goal/assign-goal.component';
import { AssignSubGoalComponent } from './components/career-manager/goal-sheet/assign-sub-goal/assign-sub-goal.component';
import { ManageSubgoalsComponent } from './components/career-manager/goal-sheet/cm-goal-sheet/manage-subgoals/manage-subgoals.component';
import { DeleteSubgoalComponent } from './components/career-manager/goal-sheet/cm-goal-sheet/delete-subgoal/delete-subgoal.component';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page/unauthorized-page.component';
import { PipeUnderScoreToSpace } from './services/pipes/pipe-underscore-to-space';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { LeaveManagementComponent } from './components/leave-management/leave-management.component';
import { BulkImportSummaryComponent } from './components/bulk-employee-import/bulk-import-summary/bulk-import-summary.component';
import { ProfileDetailComponent } from './components/career-manager/profile-detail/profile-detail.component';
import { FeedbackDialogComponent } from './components/career-manager/feedback-dialog/feedback-dialog.component';
import { FeedbackNotesComponent } from './components/career-manager/feedback-notes/feedback-notes.component';
import { ToastrModule } from 'ngx-toastr';
import { ViewSubgoalComponent } from './components/career-manager/goal-sheet/cm-goal-sheet/view-subgoal/view-subgoal.component';
import { CloseFeedbackComponent } from './components/career-manager/profile-detail/close-feedback/close-feedback.component';
import { JsonFormComponent } from './components/json-form/json-form.component';
import { AboutComponent } from './components/profile-employee/spot-light/about/about.component';
import { CertificationsComponent } from './components/profile-employee/spot-light/certifications/certifications.component';
import { EducationalQualificationsComponent } from './components/profile-employee/spot-light/educational-qualifications/educational-qualifications.component';
import { EmploymentHistoryComponent } from './components/profile-employee/spot-light/employment-history/employment-history.component';
import { ProfessionalSkillsComponent } from './components/profile-employee/spot-light/professional-skills/professional-skills.component';
import { BusinessTravelComponent } from './components/profile-employee/business-travel/business-travel.component';

import { PersonalInformationComponent } from './components/profile-employee/correspondence/personal-information/personal-information.component';
import { NomineeDetailsComponent } from './components/profile-employee/correspondence/nominee-details/nominee-details.component';
import { VisaDetailsComponent } from './components/profile-employee/business-travel/visa-details/visa-details.component';
//import { TravelBaComponent } from './components/profile-employee/business-travel/travel-ba/travel-ba.component';
import { PassportDetailsComponent } from './components/profile-employee/business-travel/passport-details/passport-details.component';
import { TravelComponent } from './components/profile-employee/business-travel/travel/travel.component';
import { UnderConstructionPopupComponent } from './components/under-construction-popup/under-construction-popup.component';
import { CalibratedReportComponent } from './components/career-manager/calibrated-report/calibrated-report.component';
import { DeclineFeedbackComponent } from './components/tasks/decline-feedback/decline-feedback.component';

import { PositionDetailsComponent } from './components/profile-employee/spot-light/position-details/position-details.component';

import { ReopenedConfirmationScreenComponent } from './reopened-confirmation-screen/reopened-confirmation-screen.component';
import { NoDataAvailableComponent } from './components/career-manager/calibrated-report/no-data-available/no-data-available.component';
import { FeedbackClosureComponent } from './components/career-manager/feedback-closure/feedback-closure.component';
import { FeedbackClosureNoteComponent } from './components/career-manager/feedback-closure-note/feedback-closure-note.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PreviewFeedbackComponent } from './components/career-manager/preview-feedback/preview-feedback.component';
import { UserSummaryComponent } from './components/career-manager/user-summary/user-summary.component';
import { CmReportComponent } from './components/career-manager/cm-report/cm-report.component';
import { CmReporteesComponent } from './components/career-manager/cm-reportees/cm-reportees.component';
import { DeactivateEmployeeComponent } from './components/employee/employee-activation/deactivate-employee/deactivate-employee.component';
import { ActivateEmployeeComponent } from './components/employee/employee-activation/activate-employee/activate-employee/activate-employee.component';
import { ConfirmDialogComponent } from './components/feedback-form-generation/confirm-dialog/confirm-dialog.component';
import { ApplyNewleaveComponent } from './components/leave-management/leave-attendance/apply-newleave/apply-newleave.component';
import { FullCalendarModule } from '@fullcalendar/angular';

import { LeaveCalendarComponent } from './components/leave-management/holiday-calendar/holiday-calendar.component';
import { RegularizeComponent } from './components/leave-management/attendance-calendar/attendance-details/regularize/regularize.component';
import { LeaveAttendanceComponent } from './components/leave-management/leave-attendance/leave-attendance.component';


import { LeaveDetailsComponent } from './components/leave-management/leave-attendance/leave-details/leave-details.component';


import { MatCheckboxModule } from '@angular/material/checkbox';
import { LeaveApprovalComponent } from './components/leave-management/leave-approval/leave-approval.component';
import { CmLeaveRejectComponent } from './components/leave-management/leave-approval/cm-leave-reject/cm-leave-reject.component';
import { RejectLeaveComponent } from './components/tasks/reject-leave/reject-leave.component';
import { WithdrawLeaveApplicationComponent} from './components/leave-management/leave-attendance/leave-details/withdraw-leave-application/withdraw-leave-application.component';
import { BulkImportHolidaysComponent } from './components/configure/bulk-import-holidays/bulk-import-holidays.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { BluealtairHolidaysComponent } from './components/configure/bluealtair-holidays/bluealtair-holidays.component';
import { ClientHolidaysComponent } from './components/configure/client-holidays/client-holidays.component';
import { RejectregularizeComponent } from './components/tasks/rejectregularize/rejectregularize.component';
import { ApplyHolidayComponent } from './components/leave-management/holiday-calendar/apply-holiday/apply-holiday.component';

import { AddholidaysComponent } from './components/configure/addholidays/addholidays.component';
import { EditHolidayComponent } from './components/configure/edit-holiday/edit-holiday.component';
import { BulkHolidaysSummaryComponent } from './components/configure/bulk-import-holidays/bulk-holidays-summary/bulk-holidays-summary.component';
import { EmployeeOnleaveComponent } from './components/employee/employee-onleave/employee-onleave.component';
import { ConfigureMenuComponent } from './components/configure-menu/configure-menu.component';
import { LeavePolicyConfigurationComponent } from './components/configure-menu/leave-policy-configuration/leave-policy-configuration.component';
import { ConfigureLeaveDialogComponent } from './components/configure-menu/configure-leave-dialog/configure-leave-dialog.component';
import { ConfigureEmployeeLeaveDialogComponent } from './components/configure-menu/configure-employee-leave-dialog/configure-employee-leave-dialog.component';
import { DetailLeaveComponent } from './components/configure-menu/detail-leave/detail-leave.component';
import { ProbationReportComponent } from './components/career-manager/probation-end/probation-report/probation-report/probation-report.component';
import { ProbationDialogComponent } from './components/career-manager/probation-dialog/probation-dialog/probation-dialog.component';
import { ProbationFeedbackClosureComponent } from './components/career-manager/probation-feedback-closure/porbation-feedback-closure/probation-feedback-closure.component';
import { ProbationJsonFormComponent } from './components/json-form/probation-json-form/probation-json-form/probation-json-form.component';
import { BulkConfigureLeaveComponent } from './components/configure-menu/bulk-configure-leave/bulk-configure-leave.component';
import { BulkExcelUploadComponent } from './components/configure-menu/bulk-excel-upload/bulk-excel-upload.component';
import { BulkExcelSummaryComponent } from './components/configure-menu/bulk-excel-summary/bulk-excel-summary.component';
import { KnowledgeTransferComponent } from './components/tasks/knowledgeTransfer/knowledge-transfer/knowledge-transfer.component';
import { ResignationAcceptComponent } from './components/tasks/resignation/resignation-accept/resignation-accept/resignation-accept.component';
import { ResignationRejectComponent } from './components/tasks/resignation/resignation-reject/resignation-reject/resignation-reject.component';
import { RealTimeFeedbackComponent } from './components/profile-employee/public-profile/real-time-feedback.component';
import { AddRealTimeFeedbackComponent } from './components/profile-employee/add-real-time-feedback/add-real-time-feedback.component';
import { ExportEmployeeDetailsComponent } from './components/employee/export-employee-details/export-employee-details.component'; 
import { AddNewFeedbackComponent } from './components/career-manager/add-new-feedback/add-new-feedback.component';
import { AddRealtimeFeedbackComponent } from './components/career-manager/add-realtime-feedback/add-realtime-feedback.component';
import { LeaveStatusComponent } from './components/leave-management/leave-attendance/leave-status/leave-status.component';
import { DailyAttendanceComponent } from './components/home/daily-attendance/daily-attendance.component';
import { ReporteesLeaveComponent } from './components/home/reportees-leave/reportees-leave.component';
import { BirthdaysEventComponent } from './components/home/birthdays-event/birthdays-event.component';
import { AnniversaryEventComponent } from './components/home/anniversary-event/anniversary-event.component';
import { WorkingFromOfficeComponent } from './components/home/working-from-office/working-from-office.component';
import { ApplyWfoComponent } from './components/home/working-from-office/apply-wfo/apply-wfo.component';
import { DeleteFeedbackComponent } from './components/profile-employee/public-profile/delete-feedback/delete-feedback.component';
import { FeedbackSummeryComponent } from './components/profile-employee/public-profile/feedback-summery/feedback-summery.component';
import { LeaveWarning } from './components/leave-management/leave-attendance/apply-newleave/leave-warning/leave-warning';
import { WfoDetailComponent } from './components/home/working-from-office/wfo-detail/wfo-detail.component';
import { IsWithdrawBoxComponent } from './components/home/working-from-office/wfo-detail/is-withdraw-box/is-withdraw-box.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PageNotFoundComponent } from './components/error-screens/page-not-found/page-not-found.component';
import { AccessDeniedComponent } from './components/error-screens/access-denied/access-denied.component';
import { ServiceUnavailableComponent } from './components/error-screens/service-unavailable/service-unavailable.component';
import { ErrorHeaderComponent } from './components/error-screens/error-header/error-header.component';
import { SessionTimeoutComponent } from './components/error-screens/session-timeout/session-timeout.component';
import { UnderConstructionComponent } from './components/error-screens/under-construction/under-construction.component';
import { TaskdialogComponent } from './components/tasks/taskdialog/taskdialog.component';
import { SearchEmployeeComponent } from './components/pages/search-employee/search-employee.component';
import { EmergencyContactComponent } from './components/profile-employee/correspondence/emergency-contact/emergency-contact.component';
import { AddressComponent } from './components/profile-employee/correspondence/address/address.component';
import { LeaveHistoryComponent } from './components/leave-management/leave-history/leave-history.component';
import { IsDeleteBoxComponent } from './components/leave-management/leave-history/is-delete-box/is-delete-box.component';
import { AttendanceCalendarComponent } from './components/leave-management/attendance-calendar/attendance-calendar.component';
import { AttendanceDetailsComponent } from './components/leave-management/attendance-calendar/attendance-details/attendance-details.component';
import { LeaveBalanceComponent } from './components/leave-management/leave-balance/leave-balance.component';
import { LeaveBalanceHistoryComponent } from './components/leave-management/leave-balance/leave-balance-history/leave-balance-history.component';import { PendingRegulariztionDetailsComponent } from './components/leave-management/attendance-calendar/pending-regulariztion-details/pending-regulariztion-details.component';
import { ApprovedRegularizationsComponent } from './components/leave-management/attendance-calendar/approved-regularizations/approved-regularizations.component';
import { AbsolutePipe } from './services/pipes/absolute';
import { EmployeeConfigComponent } from './components/employee-config/employee-config.component';
import { NewEmployeesComponent } from './components/employee-config/new-employees/new-employees.component';
import { ActiveEmployeesComponent } from './components/employee-config/active-employees/active-employees.component';
import { AddNewEmployeeCardComponent } from './components/employee-config/add-new-employee-card/add-new-employee-card.component';
import { AluminiComponent } from './components/employee-config/alumini/alumini.component';
import { OrderByApprovalStatusPipe } from './order-by-approval-status.pipe';
import { WithdrawRegularizationComponent } from './components/leave-management/attendance-calendar/pending-regulariztion-details/withdraw-regularization/withdraw-regularization.component';
import { LeaveConfigComponent } from './components/leave-config/leave-config.component';
import { EmployeeLeaveConfigComponent } from './components/leave-config/employee-leave-config/employee-leave-config.component';
import { LeavePolicyConfigComponent } from './components/leave-config/leave-policy-config/leave-policy-config.component';
import { ViewLeavebalanceDialogComponent } from './components/leave-config/view-leavebalance-dialog/view-leavebalance-dialog.component';
import { SingleEmployeeConfigureComponent } from './components/leave-config/single-employee-configure/single-employee-configure.component';
import { AddClientHolidayComponent } from './components/configure/client-holidays/add-client-holiday/add-client-holiday.component';
import { BulkLeaveConfigureComponent } from './components/leave-config/bulk-leave-configure/bulk-leave-configure.component';
import { EmployeeOnLeaveComponent } from './components/employee-config/employee-on-leave/employee-on-leave.component';
import { EmployeeSelectionComponent } from './components/configure/client-holidays/add-client-holiday/employee-selection/employee-selection.component';
import { PrievewdialogComponent } from './components/configure/client-holidays/add-client-holiday/prievewdialog/prievewdialog.component';
import { UnmarkedEmployeeComponent } from './components/employee-config/unmarked-employee/unmarked-employee.component';
import { PolicyConfigureBoxComponent } from './components/leave-config/policy-configure-box/policy-configure-box.component';
import { DeletePolicyComponent } from './components/leave-config/leave-policy-config/delete-policy/delete-policy.component';
import { UnderConstructionDialogueComponent } from './components/under-construction-dialogue/under-construction-dialogue.component';
import { OfficeAttendanceComponent } from './components/home/office-attendance/office-attendance.component';
import { OfficeAttendanceAdminComponent } from './components/home/office-attendance/office-attendance-admin/office-attendance-admin/office-attendance-admin.component';
import { OfficeAttendanceConfigComponent } from './components/home/office-attendance/office-attendance-config/office-attendance-config.component';
import { OfficeAttendanceImportDialogComponent } from './components/home/office-attendance/office-attendance-import-dialog/office-attendance-import-dialog.component';
import { ExportOfficeAttendanceComponent } from './components/home/office-attendance/export-office-attendance/export-office-attendance.component';

export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)) {
      const [_, day, month, year] = value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return super.parse(value); // Fallback to the default parsing.
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    LoginComponent,
    EditAppComponent,
    DynamicFormComponent,
    DynamicFormViewComponent,
    TestParentComponent,
    FeedbackFormsComponent,
    SpinnerComponent,
    CircleLoaderComponent,
    FeedbackGenerationComponent,
    ProjectAccordianComponent,
    FeedbackGenerationDialogComponent,
    CreateFormsDialogComponent,
    FeedbackTabsComponent,
    FeedbackTabContentComponent,
    FormTemplateDialogComponent,
    EmployeeComponent,
    MainContentComponent,
    EmployeeCardComponent,
    AddEmployeeCardComponent,
    ProjectComponent,
    MainContentProjectComponent,
    ProjectCardComponent,
    AddProjectCardComponent,
    ProjectDetailViewComponent,
    CareerManagerComponent,
    FeedbackGenerationCMComponent,
    DirectReporteesCMComponent,
    FeedbackFormGenerationComponent,
    MainContentFeedbackComponent,
    FeedbackFormCardSharedComponent,
    FeedbackFormCardNotificationComponent,
    EvaluationConfigurationComponent,
    OneOnOneMeetingComponent,
    ParticipantCardComponent,
    GoalSheetComponent,
    GoalSheetAccordianComponent,
    SubGoalTabComponent,
    ProgressBarComponent,
    AddGoalComponent,
    GoalSheetViewEmployeeComponent,
    AngularSpinnerComponent,
    AverageScoreComponent,
    DeclineFeedbackComponent,
    DoughnutChartComponent,
    HorizontalBarChartComponent,
    AllEmployeeReviewComponent,
    PagesComponent,
    SidebarMenuComponent,
    HomeComponent,
    LeaveSummaryComponent,
    UpcomingHolidaysComponent,
    UpcomingEventsComponent,
    MyProjectsComponent,
    MyTeamComponent,
    NotificationsCarouselComponent,
    NotificationsCarouselComponent,
    EmployeeUnmarkedComponent,
    DeleteEmployeeComponent,
    BulkEmployeeComponent,
    EmployeeImportComponent,
    BulkSummaryComponent,
    TaskComponent,
    TasksComponent,
    AssetMainComponent,
    AssetManagementCardComponent,
    AddAssetComponent,
    AssetCardComponent,
    ViewAssetComponent,
    RevokeAssetComponent,
    MyTaskComponent,
    ResignationemailComponent,
    ResignationEmailDialogComponent,
    RelivingProcessComponent,
    ProfileEmployeeComponent,
    ExitChecklistComponent,
    SpotLightComponent,
    CorrespondenceComponent,
    ClientDetailsComponent,
    AssignAssetComponent,
    AddEditClientComponent,
    ViewClientComponent,
    AddTalentComponent,
    UnauthorizedPageComponent,
    PipeUnderScoreToSpace,
    AbsolutePipe,
    UnderConstructionComponent,
    LeaveManagementComponent,
    BulkImportSummaryComponent,
    CmGoalSheetComponent,
    AssignGoalComponent,
    AssignSubGoalComponent,
    ManageSubgoalsComponent,
    DeleteSubgoalComponent,
    UnauthorizedPageComponent,
    ProfileDetailComponent,
    FeedbackDialogComponent,
    FeedbackNotesComponent,
    ViewSubgoalComponent,
    CloseFeedbackComponent,
    JsonFormComponent,
    AboutComponent,
    CertificationsComponent,
    EducationalQualificationsComponent,
    EmploymentHistoryComponent,
    ProfessionalSkillsComponent,
    BusinessTravelComponent,
    PersonalInformationComponent,
    NomineeDetailsComponent,
    VisaDetailsComponent,

    PassportDetailsComponent,
    TravelComponent,
    UnderConstructionPopupComponent,
    CalibratedReportComponent,
    DeclineFeedbackComponent,
    PositionDetailsComponent,
    ReopenedConfirmationScreenComponent,
    NoDataAvailableComponent,
    FeedbackClosureComponent,
    FeedbackClosureNoteComponent,
    PreviewFeedbackComponent,
    UserSummaryComponent,
    CmReportComponent,
    CmReporteesComponent,
    DeactivateEmployeeComponent,
    ActivateEmployeeComponent,
    ConfirmDialogComponent,
    ApplyNewleaveComponent,
    LeaveCalendarComponent,
    RegularizeComponent,
    LeaveAttendanceComponent,
    ExportEmployeeDetailsComponent,


    LeaveDetailsComponent,
    LeaveApprovalComponent,
    CmLeaveRejectComponent,
    RejectLeaveComponent,
    WithdrawLeaveApplicationComponent,
    BulkImportHolidaysComponent,
    ConfigureComponent,
    BluealtairHolidaysComponent,
    ClientHolidaysComponent,
    RejectregularizeComponent,
    ApplyHolidayComponent,
    AddholidaysComponent,
    EditHolidayComponent,
    BulkHolidaysSummaryComponent,
    EmployeeOnleaveComponent,
    ConfigureMenuComponent,
    LeavePolicyConfigurationComponent,
    ConfigureLeaveDialogComponent,
    ProbationReportComponent,
    ProbationDialogComponent,
    ProbationFeedbackClosureComponent,
    ProbationJsonFormComponent,
    ConfigureEmployeeLeaveDialogComponent,
    BulkConfigureLeaveComponent,
    BulkExcelUploadComponent,
    BulkExcelSummaryComponent,
    DetailLeaveComponent,
    KnowledgeTransferComponent,
    ResignationAcceptComponent,
    ResignationRejectComponent,
    RealTimeFeedbackComponent,
    AddRealTimeFeedbackComponent,
    AddRealtimeFeedbackComponent,
    AddNewFeedbackComponent,
    LeaveStatusComponent,
    DailyAttendanceComponent,
    ReporteesLeaveComponent,
    BirthdaysEventComponent,
    AnniversaryEventComponent,
    WorkingFromOfficeComponent,
    ApplyWfoComponent,
    DeleteFeedbackComponent,
    FeedbackSummeryComponent,
    LeaveWarning,
    WfoDetailComponent,
    IsWithdrawBoxComponent,
    NotificationsComponent,
    PageNotFoundComponent,
    AccessDeniedComponent,
    ServiceUnavailableComponent,
    ErrorHeaderComponent,
    SessionTimeoutComponent, TaskdialogComponent, SearchEmployeeComponent, EmergencyContactComponent, AddressComponent, LeaveHistoryComponent, IsDeleteBoxComponent, AttendanceCalendarComponent, AttendanceDetailsComponent, LeaveBalanceComponent, LeaveBalanceHistoryComponent,
    SessionTimeoutComponent, TaskdialogComponent, SearchEmployeeComponent, LeaveHistoryComponent, IsDeleteBoxComponent, AttendanceCalendarComponent, AttendanceDetailsComponent, PendingRegulariztionDetailsComponent, ApprovedRegularizationsComponent, EmployeeConfigComponent, NewEmployeesComponent, ActiveEmployeesComponent, AddNewEmployeeCardComponent, AluminiComponent, OrderByApprovalStatusPipe, WithdrawRegularizationComponent, LeaveConfigComponent, EmployeeLeaveConfigComponent, LeavePolicyConfigComponent, ViewLeavebalanceDialogComponent, SingleEmployeeConfigureComponent, BulkLeaveConfigureComponent, AddClientHolidayComponent, EmployeeOnLeaveComponent, EmployeeSelectionComponent, PrievewdialogComponent, UnmarkedEmployeeComponent, PolicyConfigureBoxComponent, DeletePolicyComponent,
    UnderConstructionDialogueComponent,
    OfficeAttendanceComponent,
    OfficeAttendanceAdminComponent,
    OfficeAttendanceConfigComponent,
    OfficeAttendanceImportDialogComponent,
    ExportOfficeAttendanceComponent,

  ],

  imports: [
    BrowserModule,
    MatCardModule,
    FullCalendarModule,

    PdfViewerModule,
    AppRoutingModule,
    NgxChartsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MaterialModule,
    PrimengModule,
    FlexLayoutModule,
    OrderModule,
    FormsModule,
    MatGridListModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DndModule,
    MatDialogModule,
    MatBadgeModule,
    NgbModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CommonModule,
    MatPaginatorModule,
    LazyLoadImageModule,
    MatProgressBarModule,
    ChartsModule,

    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    CKEditorModule,
    MatRadioModule,

    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right'

    }),
    SliderModule,
    MatExpansionModule,
    NgxMatIntlTelInputModule,
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        postLogoutRedirectUri: OAuthSettings.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: 'localStorage',

      }
    }),
    StoreModule.forRoot({ tasks: taskReducer }),
    EffectsModule.forRoot([TaskEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatCheckboxModule,
  ],

  providers: [
    SidenavService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FgHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-GB'
    },
    { 
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { autoFocus: false } 
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [EditAppComponent, DynamicFormComponent, FeedbackDialogComponent],
})
export class AppModule { }
