import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { OfficeAttendanceImportDialogComponent } from '../../office-attendance-import-dialog/office-attendance-import-dialog.component';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { ExportOfficeAttendanceComponent } from '../../export-office-attendance/export-office-attendance.component';

@Component({
  selector: 'app-office-attendance-admin',
  templateUrl: './office-attendance-admin.component.html',
  styleUrls: ['./office-attendance-admin.component.scss']
})
export class OfficeAttendanceAdminComponent implements OnInit {

  isSpinnerActive: boolean = true;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  attendances: any = [];
  filteredAttendances: any = [];
  selectedDate: any;
  page: number = 1;
  pageSize: number = 20; 
  totalPages: number = 0;
  searchQuery: string = ''; 
  selectedDepartments: any = [];
  selectedJobTitles: any = [];
  sortConfig = { column: '', direction: 'asc' };
  jobTitleSearch: Array<string> = [];
  capabilitySearch: Array<string> = [];
  
  departments = [];
  jobTitles=[];
  

  constructor(private dialog: MatDialog,
              private officeAttendanceService: OfficeAttendanceService) {}

  ngOnInit(): void {
    this.setDate();
    this.getAllAttendanceRecords();
    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }

    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }
  }

  setDate() {
    const today = new Date();
    this.selectedDate = new Date(today);
    this.selectedDate.setDate(today.getDate() - 1);
    this.selectedDate = this.formatDate(this.selectedDate);
  }

  onDateChange(event: any): void {
    this.selectedDate = event.value;
    this.selectedDate = this.formatDate(this.selectedDate);
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onSearchInput(value: string): void {
    this.searchQuery = value.trim();
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onFiltreChange(): void {
    this.selectedDepartments = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
    this.selectedJobTitles = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
    console.log(this.selectedJobTitles)
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  

  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }

  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.getAllAttendanceRecords();
  }

  getAllAttendanceRecords() {
    this.isSpinnerActive = true;
    this.attendances = [];
    this.filteredAttendances = [];

    this.officeAttendanceService.getAllAttendanceRecords(
      this.selectedDate,
      this.page - 1,
      this.pageSize,
      this.searchQuery,
      this.selectedDepartments,
      this.selectedJobTitles
    ).subscribe(res => {
      this.attendances = res.body?.content || [];
      this.filteredAttendances = [...this.attendances];
      this.totalPages = res.body?.totalPages || 0
      this.isSpinnerActive = false;
    }, () => {
      this.isSpinnerActive = false;
    });
    
  }

  openExportOptions(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "40%";
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ExportOfficeAttendanceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { });
  }

  addBulkAttendanceRecords() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '75%';
    dialogConfig.height = '75%';
    dialogConfig.data = {hideReupload: false};

    const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }


  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
}
