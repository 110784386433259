import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.scss'],
})
export class SearchEmployeeComponent implements OnInit {
  filteredUsers: Observable<User[]>;
  allUsers: any[] = [];
  selectedUser: any;
  userdata: User;
  searchText = '';
  userControl: FormControl = new FormControl();
  userImageThumbnail: string;

  constructor(
    private userservice: UserService,
    public userDetailService: UserDetailsService,
    private feedbackService: FeedbackService,
    private router: Router,
    public dialogRef: MatDialogRef<SearchEmployeeComponent>
  ) {}

  ngOnInit(): void {
    this.allUsers = [];
    this.userservice.getAllUserForSearchEmployee().subscribe((res) => {
      for( let user of res.body){
        if(!user?.activated){
          continue;
        }
        let userImageThumbnail = '';
        if(user?.userImageThumbnail !== ''){
          userImageThumbnail = 'data:image/png;base64,' + user.userImageThumbnail;
        }
        let userDetail = {
          employeeId: user?.employeeId?.toUpperCase(),
          firstName: user?.firstName,
          lastName: user?.lastName,
          userID: user?.userID,
          userImageThumbnail: userImageThumbnail,
        };
    
        this.allUsers.push(userDetail);
        
      }
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterAndSortUsers(value))
      );
    });
  }

  filterAndSortUsers(value: string): User[] {
    const filterValue = value.toLowerCase().trim();

    const filteredUsers = this.allUsers.filter((user) => {
        // Trim spaces from first and last name to avoid unwanted extra spaces
        const firstName = user.firstName.trim().toLowerCase();
        const lastName = user.lastName.trim().toLowerCase();
        
        // Ensure a single space between first name and last name
        const fullName = `${firstName} ${lastName}`;

        return (
            firstName.includes(filterValue) ||
            lastName.includes(filterValue) ||
            fullName.includes(filterValue)
        );
    });

    return filteredUsers.sort((a, b) => {
        const aFirstName = a.firstName.trim().toLowerCase();
        const bFirstName = b.firstName.trim().toLowerCase();
        const aLastName = a.lastName.trim().toLowerCase();
        const bLastName = b.lastName.trim().toLowerCase();

        const aExactMatch = aFirstName === filterValue;
        const bExactMatch = bFirstName === filterValue;

        const aStartsWith = aFirstName.startsWith(filterValue);
        const bStartsWith = bFirstName.startsWith(filterValue);

        const aLastNameStartsWith = aLastName.startsWith(filterValue);
        const bLastNameStartsWith = bLastName.startsWith(filterValue);

        // 1. Exact first name match comes first
        if (aExactMatch && !bExactMatch) return -1;
        if (!aExactMatch && bExactMatch) return 1;

        // 2. First name starts with search term
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        // 3. Last name starts with search term
        if (aLastNameStartsWith && !bLastNameStartsWith) return -1;
        if (!aLastNameStartsWith && bLastNameStartsWith) return 1;

        // 4. Otherwise, sort alphabetically by full name
        const aFullName = `${aFirstName} ${aLastName}`;
        const bFullName = `${bFirstName} ${bLastName}`;

        return aFullName.localeCompare(bFullName);
    });
  }

  

  selectUser(user: any) {
    if (user) {
      this.selectedUser = user;
      this.navigateToEmployee();
      this.userControl.setValue('');
    } else {
      this.selectedUser = null;
      this.userControl.setValue('');
      return;
    }
  }

  navigateToEmployee() {
    this.feedbackService.sendData(this.selectedUser.userID);
    this.router.navigate([
      `/employee/employee-profile/${this.selectedUser.userID}`,
    ]);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
