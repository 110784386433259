<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <label class="header_name">Probation Ending Feedback</label>
      <label class="reporteename"
        >{{
          this.directReporteeId.directReporteeDetail.firstName +
            " " +
            this.directReporteeId.directReporteeDetail.lastName | titlecase
        }}
      </label>
    </div>

    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">
        <img class="close-icon-svg" 
            src="assets/icons/dashboard-icons/close-pink-icon.svg" />
      </button>
    </div>
  </div>
  <hr class="hr-row" />
  <form [formGroup]="feedbackDetails">
    <div class="row">
      <div class="col-md-5" style="padding: unset">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Feedback Type</mat-label>
          <mat-select formControlName="feedbackType" [disabled]="!this.isEdit">
            <mat-option *ngFor="let form of this.projects" [value]="form">
              {{ form | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-1 mt-2 mb-2"
        *ngIf="this.directReporteeId.dataFeedback != null"
      >
        <div (click)="deleteFeedbackGroup()" class="delete-btn">Delete</div>
      </div>
      <ng-template [ngIf]="selectedFormName === 'form3'"> </ng-template>
    </div>
    <div class="display-flex mt-2">
      <h1 class="add-heading">Add Opinion</h1>
      <button class="add-btn" (click)="addComponent()">Add</button>
    </div>
    <div
      class="row"
      [formGroup]="feedbackOpinion"
      *ngFor="let feedback of this.opinion().controls; let i = index"
    >
      <div *ngIf="!isVisible[i]" [formGroupName]="i">
        <mat-form-field appearance="outline">
          <mat-label>Employee</mat-label>
          <mat-select
            formControlName="feedbackFrom"
            (selectionChange)="getUserID($event.value, i)"
            (openedChange)="focusSearchField($event)"
            panelClass="custom-dropdown"
          >
            <input
              class="myInput"
              #myInput
              matInput
              focused="'true'"
              type="text"
              (keyup)="search($event.target.value, i)"
              (focusout)="focusOutEvent(i)"
              autocomplete="off"
              placeholder="search name"
            />
            <mat-option
              *ngFor="let user of this.displaySelectedArea[i]"
              [value]="user"
            >
              {{ user }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>

      <div
        *ngIf="
          feedbackDetails.value.feedbackArray[i].feedbackRole ==
            'SELF_EVALUATION' ||
          feedbackDetails.value.feedbackArray[i].feedbackRole ==
            'CAREER_MANAGER'
        "
        class="self-user-div"
      >
        {{
          feedbackDetails.value.feedbackArray[i].feedbackRole ==
          "SELF_EVALUATION"
            ? directReporteeId.directReporteeDetail.firstName +
              " " +
              directReporteeId.directReporteeDetail.lastName
            : this.assinedManagerUseAfterParse.firstName +
              " " +
              this.assinedManagerUseAfterParse.lastName
        }}
      </div>
      <div
        class="myInput"
        *ngIf="
          isVisible[i] &&
          !(
            feedbackDetails.value.feedbackArray[i].feedbackRole ==
              'SELF_EVALUATION' ||
            feedbackDetails.value.feedbackArray[i].feedbackRole ==
              'CAREER_MANAGER'
          )
        "
        [formGroupName]="i"
      >
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Employee</mat-label>
          <mat-select
            formControlName="feedbackFrom"
            (selectionChange)="getUserID($event.value, i)"
            disabled="true"
          >
            <mat-option *ngFor="let user of this.uList" [value]="user">
              {{ user }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          !isVisible[i] &&
          !(
            feedbackDetails.value.feedbackArray[i].feedbackRole ==
              'SELF_EVALUATION' ||
            feedbackDetails.value.feedbackArray[i].feedbackRole ==
              'CAREER_MANAGER'
          )
        "
        [formGroupName]="i"
        class="col-md-3 unset-max-width"
      >
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Role</mat-label>
          <mat-select
            formControlName="feedbackRole"
            (selectionChange)="selectedRole($event.value, i)"
          >
            <mat-option
              *ngFor="let role of this.FeedbackRole"
              [value]="role"
              [disabled]="role === 'REVERSE_PROBATION_FEEDBACK'"
            >
              {{ role | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="isVisible[i]"
        [formGroupName]="i"
        class="col-md-3 unset-max-width"
      >
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Role</mat-label>
          <mat-select
            formControlName="feedbackRole"
            (selectionChange)="selectedRole($event.value, i)"
            disabled="true"
          >
            <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
              {{ role | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="feedback.value.feedbackSubmissionStatus != 'SUBMITTED'"
        (click)="removefeedbackopinion(feedback.value.id, i)"
        class="delete-btn"
      >
        Delete
      </div>
      <div
        *ngIf="feedback.value.feedbackSubmissionStatus === 'SUBMITTED'"
        class="submitted-btn"
      >
        SUBMITTED
      </div>
    </div>
  </form>
  <div class="div_submit_btn">
    <button
      mat-raised-button
      (click)="onSubmit()"
      class="submit-btn"
      type="submit"
    >
      Submit
    </button>
  </div>
</div>
