import { Component, Inject, OnInit, ViewChild,ElementRef,Renderer2   } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { th } from 'date-fns/locale';
import { Toast, ToastRef, ToastrService } from 'ngx-toastr';
import { FeedbackRole } from 'src/app/services/feedback/feedback-enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-new-feedback',
  templateUrl: './add-new-feedback.component.html',
  styleUrls: ['./add-new-feedback.component.scss'],
})
export class AddNewFeedbackComponent implements OnInit {
  @ViewChild('myInput') myInput!: ElementRef;
  name: string = '';
  email: string = '';
  userNameIDMapping = {};
  userIdNameMapping = {};
  temp = {};
  userList;
  searchValue = '';
  userID: any;
  FeedbackRole = [];
  feedbackFrom;
  feedbackRole;
  weightage;
  groupId;
  formGroup: FormGroup;
  displaySelectedArea;
  checkSum=0;
  emailError: string;
  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddNewFeedbackComponent>,
    public toaster: ToastrService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
  
    this.feedbackFrom = this.data[1];
    this.feedbackRole = this.data[2];
    this.weightage = this.data[3];
    this.checkSum = this.data[4]-this.data[3];
    this.formGroup = this.fb.group({
      weightage: ['  ', [Validators.required]],
      feedbackFrom: ['  ', [Validators.required]],
      feedbackRole: ['  ', [Validators.required]],
    });
    this.formGroup.get('weightage').setValue(this.data[3]);
    this.formGroup.get('feedbackFrom').setValue(this.data[1]);
    this.formGroup.get('feedbackRole').setValue(this.data[2]);

    this.userService.getAllUserForDropDown().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        // if (element.userID != this.data[0]) {} // this condition removed for the case of self evaluation
        if (element.activated ) {
          this.userNameIDMapping[
            element.firstName +
              ' ' +
              element.lastName +
              '(' +
              element.employeeId+
              ')'
          ] = element.userID;
          this.userIdNameMapping[element.userID] =
            element.firstName +
            ' ' +
            element.lastName +
            '(' +
            element.employeeId +
            ')';
          this.temp[element.firstName + ' ' + element.lastName] =
            element.userID.toUpperCase();
        }
        this.feedbackFrom = this.userIdNameMapping[this.feedbackFrom];
      });
      this.formGroup
        .get('feedbackFrom')
        .setValue(this.userIdNameMapping[this.data[1]]);
      this.userList = Object.keys(this.userNameIDMapping);
      this.displaySelectedArea = this.userList;
    });
    for (let role of Object.values(FeedbackRole)) {
      if (isNaN(Number(role))) {
        this.FeedbackRole.push(role);
      }
    }
  }
  focusSearchField(isOpened: boolean) {
    if (isOpened) {
      setTimeout(() => {
        if (this.myInput) {
          this.myInput.nativeElement.focus();
        }
      }, 100); 
    }
  }
  ngAfterViewInit() {
    // Attach a click event listener to the dropdown panel
    setTimeout(() => {
      const dropdownPanel = document.querySelector('.custom-dropdown');
      if (dropdownPanel) {
        this.renderer.listen(dropdownPanel, 'click', () => {
          if (this.myInput) {
            this.myInput.nativeElement.focus();
          }
        });
      }
    }, 500); // Delay to ensure dropdown is available
  }
  AfterViewInit() {
    setTimeout(() => {
      if (this.myInput) {
        this.myInput.nativeElement.focus();
      }
    }, 100); 
    if (this.myInput) {
      this.myInput?.nativeElement.focus();
    } else {
      console.warn('ViewChild not initialized yet');
    }
  }
  
  openSearchPanel(isOpen: boolean) {
    if (isOpen) {
      setTimeout(() => {
        this.myInput?.nativeElement.focus(); // Focus the input when dropdown opens
      }, 200); // Small delay to ensure dropdown is fully rendered
    }
  }
  onWeightagetyped(event) {
    let temp = Number(event);
  
    const hasMoreThanTwoDecimals = (value: string | number): boolean => {
      const valueString = value.toString();
      const decimalPart = valueString.split('.')[1];
      return decimalPart && decimalPart.length > 2; // True if more than 2 decimal places
    };
  
    if (hasMoreThanTwoDecimals(event)) {
      this.setFormControlError(
        'weightage',
        'InvalidWeightage',
        'Please enter a value with no more than two decimal places'
      );
      return; 
    }
  
    if (this.checkSum + temp > 100.00) {
      this.setFormControlError(
        'weightage',
        'InvalidWeightage',
        'Please Enter Valid Percentage'
      );
      return; 
    }
  }
  
  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.formGroup.get(controlName).markAsTouched({ onlySelf: true });
    this.formGroup.get(controlName).markAsDirty({ onlySelf: true });
    this.formGroup.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg ? errorMsg : 'Enter valid percentage';
  }
  checkError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  };
  search(query: string) {
    let result = this.select(query);
    if (result.length != 0) {
      this.displaySelectedArea = result;
    }
    this.displaySelectedArea = result;
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.userList) {
        if (a.toLowerCase().includes(query.toLowerCase())) {
            result.push(a);
        }
    }

    const filterValue = query.toLowerCase();
    
    return result.sort((a, b) => {
        const aLower = a.toLowerCase();
        const bLower = b.toLowerCase();

        const aStartsWith = aLower.startsWith(filterValue);
        const bStartsWith = bLower.startsWith(filterValue);

        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        return aLower.localeCompare(bLower); // Sort alphabetically if both are similar
    });
}

  focusOutEvent(i) {}

  submitForm() {

    if(this.feedbackRole==='SELF_EVALUATION'){
      if(this.formGroup.controls.weightage.value>0){
        this.toaster.warning('Weightage Cannot be greater than 0 for Self Evaluation.');
        return;
      }
    }
    this.formGroup;
    if (this.formGroup.valid) {
      let result = {
        feedbackFrom: this.formGroup.controls.feedbackFrom.value,
        feedbackRole: this.formGroup.controls.feedbackRole.value,
        weightage: this.formGroup.controls.weightage.value,
      };
      
      this.dialogRef.close(result);
    } else {
      this.toaster.error('Fill required field', 'Failed');
    }
    // Handle form submission and data processing here
  }

  getUserID(username, i) {
    this.userID = this.userNameIDMapping[username] || this.temp[username];
    this.feedbackFrom = this.userID;
  }
  selectedRole(role, i) {
    this.feedbackRole = role;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
