import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';

@Component({
  selector: 'app-export-office-attendance',
  templateUrl: './export-office-attendance.component.html',
  styleUrls: ['./export-office-attendance.component.scss']
})
export class ExportOfficeAttendanceComponent implements OnInit {
  generateButtonText: string = 'Generate Report';
  generateDisabled: boolean = false;
  availableYears: number[] = [];
  availableMonths: string[] = [];
  selectedMonth: number = 1;  
  selectedYear: number = new Date().getFullYear(); 

  constructor(public dialogRef: MatDialogRef<ExportOfficeAttendanceComponent>, 
    private officeAttendanceService: OfficeAttendanceService,
  private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.availableYears = this.getAvailableYears();
    this.availableMonths = this.getAvailableMonths();
  }

  generateReport(){
    this.generateButtonText = 'Please wait..';
    this.generateDisabled = true;
    this.officeAttendanceService.getMonthlyAttendanceReport(this.selectedMonth, this.selectedYear)
    .subscribe((response: any) => {
      let filename = this.availableMonths[this.selectedMonth-1] + "_" + this.selectedYear + "_Attendance_Report.xlsx";
    
      // Create a Blob and trigger download
      const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;  // Use extracted filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      // Reset button state
      this.generateButtonText = 'Download Report';
      this.generateDisabled = false;
    }, (error) => {
      this.toasterService.error("Error downloading the file", error)
      console.error("Error downloading the file:", error);
      this.generateDisabled = false;
    });
    }

  getAvailableYears(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  }

  getAvailableMonths(): string[] {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames;
  }


  matDialogClose() {
    this.dialogRef.close();
  }

}
